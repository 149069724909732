<template>
  <v-row class="pb-10" v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Proveedores" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_supplier'])">
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="dialogExport=true" :disabled="count === 0" :ripple="false">
          <v-list-item-title class="body-2">Descargar documentos</v-list-item-title>
        </v-list-item>
          </v-list>
        </v-menu>
        <v-query-builder class="mx-2" :query-map="filters" model="suppliers" :active.sync="activeFilter" />
        <v-btn color="primary" @click="dialogSupplier=true"><v-icon left>mdi-human-dolly</v-icon>Crear proveedor</v-btn>
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_supplier'])" />
      <skeleton-list v-else-if="loading" />
      <template v-else>
        <v-empty-state v-if="!suppliersList.length || Object.keys($route?.query).length === 0" type="suppliers" id="proveedores" customClass="mt-5" :hiddenDescription="hiddenBtnEmpty">
          <template v-slot:actions>
            <template v-if="!$route.query.ordering && hiddenBtnEmpty">
              <v-btn @click="activeFilter = true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
              <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
            </template>
          </template>
        </v-empty-state>
        <template v-else class="text-center py-5">
          <v-data-table
            :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headers"
            :items="suppliersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            v-model="selected"
            item-key="id"
            show-select
            checkbox-color="primary"
            @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}"
            style="cursor: pointer"
            :height="heightTable"
            fixed-header
            :key="reset"
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders
                :headers="headers"
                @sort="setSort"
                @toggleAll="toggleAll"
                @isSelected="isSelected = $event"
                :resetCheckbox="resetCheckbox"
              />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <template v-if="item.name || item.tax_id">
                <span class="body-1 d-inline-block text-truncate" style="max-width: 95%;" v-if="item.name">{{ item.name }}</span>
                <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
                <span class="mt-n1 caption fontDraw--text d-block" v-if="item.tax_id">{{$t('generals.RUT')}} {{item.tax_id}}</span>
              </template>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.tax_settings`]="{item}">
              <span class="d-inline-block text-truncate" style="max-width: 96%;" v-if="item.tax_settings?.cl_activity_description">{{item.tax_settings?.cl_activity_description}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | dateTimeSecond}}</span>
            </template>
            <template v-slot:[`item.group_details`]="{item}">
              <span class="d-inline-block text-truncate" style="max-width: 96%;" v-if="item.group_details?.name && item.group_details?.name !== 'Bloqueados'">{{item.group_details?.name}}</span>
              <v-chip v-else-if="item.group_details?.name === 'Bloqueados'" color="greyMedium" class="font-weight-semibold" small label>Bloqueados<v-icon right>mdi-lock</v-icon></v-chip>
              <span class="body-2 text--disabled" v-else>Sin grupo asociado</span>
            </template>
            <template v-slot:[`item.actions`]="{item}" v-if="($helpers.hasSomePermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
              <v-menu v-if="$helpers.hasSomePermission(['add_suppliergroup'])" close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small :disabled="item.group_details?.name === 'Bloqueados'"><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="handleTableAddToGroup(item, 'delete')" v-if="!!item.group_details" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center">
                      <v-icon color="secondary" size="16" left>mdi-folder-minus</v-icon>Eliminar del grupo
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" @click="handleTableAddToGroup(item)" v-else :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center">
                      <v-icon color="secondary" size="16" left>mdi-folder-plus</v-icon>Agregar a grupo
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
        </template>
      </template>
    </v-col>

    <!-- dialog confirm list -->
    <v-dialog v-model="dialogConfirm" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Cambio de lista</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirm=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">Al cambiar a la lista blanca, todos los documentos de estos proveedores, serán aceptados automáticamente.</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirm=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handleList" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm list -->

    <!-- dialog new group -->
    <v-dialog v-model="dialogGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.name.$model" :error="$v.newGroup.name.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-2">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.description.$model" :error="$v.newGroup.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="">
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="newGroup.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(newGroup.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="newGroup.members"
                      :error="$v.newGroup.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="newGroup.members.length >= 1 ? `${newGroup.members.length === 1 ? `${newGroup.members.length} seleccionado` : `${newGroup.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row class="ml-1" no-gutters>
              <v-col cols="2"></v-col>
              <v-col class="pa-0 mt-2 pl-6">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in newGroup.members.slice(0,2)" :key="`${member}${i}`" close @click:close="memberRemove(member, index)" color="lightBlue" small label close-icon="mdi-close">
                  {{member.email }}
                </v-chip>
                <template v-if="newGroup.members.length > 2">
                  <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" color="lightBlue" small label close-icon="mdi-close">
                  {{newGroup.members.length - 2}} más
                </v-chip>
                </template>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="createGroup" :loading="createGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new group -->

    <!-- dialog add to group -->
    <v-dialog v-model="dialogAddGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Agregar a grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogAddGroup=false, group=[]" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-7 pt-5 px-5" style="max-height: 600px !important;">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3" class="pt-2">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="160" />
            </v-col>
            <v-col class="pl-12 pr-0">
              <v-list class="pa-0 mt-5 transparent v-list-form-extended">
                <span class="d-block">Grupos</span>
                <v-list-item class="py-1">
                  <v-list-item-title>
                    <v-col class="pa-0">
                      <v-select
                        v-model="group"
                        hide-details
                        single-line
                        color="primary"
                        dense
                        outlined
                        :items="groupsList"
                        :chips="false"
                        item-text="name"
                        item-value="id"
                        placeholder="Selecciona el grupo"
                        no-data-text="No existen grupos para mostrar"
                      />
                    </v-col>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAddGroup=false, group=[]">Cancelar</v-btn>
          <v-btn color="primary" @click="addToGroup" :loading="createGroupLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog add to group -->

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="709" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar del grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-segment-destroy.svg`)" :width="150" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar un proveedor del grupo, este quedará sin esa asociación. Es importante tener en cuenta que el proveedor en sí no será eliminado.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroySuppliertGroups" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Descarga de proveedores</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
            <v-list class="pa-0 transparent">
              <v-list-item class="mt-2">
                <v-list-item-content class="pt-0">
                  <v-list-item-title class="fontBody--text body-1 font-weight-bold">Selecciona el formato de los archivos:</v-list-item-title>
                  <v-list-item-subtitle class="pt-2">
                    <v-row class="ml-n1" no-gutters>
                      <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                        <v-checkbox class="ma-0" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV'].includes(file) && count >= 1000000" dense hide-details />
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-2 px-5">
            <v-spacer/>
            <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
            <v-btn color="primary" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->

    <!-- dialog new customer -->
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false, clearInput()" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="fontBody--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model.trim="$v.newSupplier.tax_id.$model" :error="$v.newSupplier.tax_id.$error" v-dni="newSupplier.country" required outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="fontBody--text body-1">Nombre de la empresa</span>
              <v-text-field v-model.trim="$v.newSupplier.name.$model" :error="$v.newSupplier.name.$error" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-col class="px-5">
            <span class="fontBody--text body-1">Actividad económica</span>
            <v-text-field v-model.trim="$v.newSupplier.tax_settings.cl_activity_description.$model" :error="$v.newSupplier.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
          </v-col>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="fontBody--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.newSupplier.address.$model" :error="$v.newSupplier.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="fontBody--text body-1">{{$t('generals.Comuna')}}</span>
              <v-select v-model="$v.newSupplier.state.$model" :error="$v.newSupplier.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <template v-if="($helpers.hasSomePermission(['view_suppliergroup']) && $store?.state?.auth?.account?.suppliergroup_feature)">
            <div class="px-5 pt-1">
              <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-semibold">
                Selecciona el grupo al que deseas agregar al proveedor
                <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
              </v-toolbar-title>

              <div class="pa-0">
                <span class="fontBody--text body-1">Grupos</span>
                <v-col class="pa-0">
                  <v-select
                    v-model="newSupplier.group"
                    hide-details
                    single-line
                    color="primary"
                    dense
                    outlined
                    :items="groupsList"
                    :chips="false"
                    item-text="name"
                    item-value="id"
                    no-data-text="No existen grupos para mostrar"
                  />
                </v-col>
              </div>
            </div>
            <v-divider class="my-4"/>
          </template>

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 fontBody--text font-weight-semibold">
              Agregar contactos
              <v-chip class="ml-2 px-1" x-small color="off">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="fontBody--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="fontBody--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="fontBody--text body-1">Teléfono</span>
                  <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0 pl-2" style="max-width: 30px;">
                  <v-btn class="mt-5 ml-n2" v-if="i >= 1 || contacts.length > 1" @click="contacts.splice(i, 1)" text color="secondary" :ripple="false"><v-icon color="primary">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn v-if="contacts.length < 10" @click.prevent="addRowContacts" text color="primary" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false, clearInput()">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.suppliers.createLoader" @click="postSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new customer -->
  </v-row>
</template>
<script>
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf, email } from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    contacts: [{ name: '', email: '', phone: '' }],
    types: [],
    dialogExport: false,
    dialogDelete: false,
    reset: 0,
    auxSelectedList: [],
    group: [],
    newGroup: {
      name: '',
      visibility: 3,
      description: '',
      suppliers: [],
      members: []
    },
    createGroupLoading: false,
    dialogAddGroup: false,
    dialogGroup: false,
    oldSelected: null,
    isClickCard: false,
    isClickCheckbox: false,
    resetCheckbox: 0,
    actionSelected: '',
    selectedList: [],
    selected: [],
    activeFilter: false,
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    newSupplier: {
      name: '',
      tax_id: '',
      address: '',
      group: null,
      supplier_group_ids: [],
      tax_settings: {
        cl_activity_description: ''
      },
      contacts: [],
      country: 'CL',
      state: ''
    },
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'economic_activity',
        label: 'Actividad económica',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      },
      {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        value2: null,
        category: 'b',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
       type: 'select',
       isMultiple: false,
       id: 'group',
       value: null,
       label: 'Grupo',
       category: 'c',
       choices: []
     }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUT', value: 'tax_id', sortable: true },
      { text: 'Actividad económica', value: 'tax_settings', sortable: true },
      { text: 'Grupo', value: 'group_details', sortable: true },
      { text: 'Fecha de creación', value: 'created', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    dialogSupplier: false,
    loading: false,
    options: {
      white: ['Sacar de lista blanca', 'Cambiar a lista negra'],
      black: ['Sacar de lista negra', 'Cambiar a lista blanca'],
      null: ['Cambiar a lista blanca', 'Cambiar a lista negra']
    },
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Proveedores'
        }
      ]
    },
    errorXML: null,
    errorCSV: null,
    fileFormat: ['XLSX', 'CSV']
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList,
      membersList: state => state.accounts.membersList,
      groupsList: state => state.suppliers.groupsList
    }),
    country () {
      return this.newSupplier.country ? this.countriesList.find(country => country.id === this.newSupplier.country) : null
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.suppliersList.length) return false
      return false
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && this.suppliersList.length < 17) return 'auto'
      return 'calc(100vh - 210px)'
    }
  },
  watch: {
    mode (val) {
      this.reset += 1
      this.selectedList = []
      this.selected = []
      this.auxSelectedList = []
      this.actionSelected = ''
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', '')
    },
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    },
    '$store.state.documents.sendTypeToggleDropdown' (val) {
      this.selectedList = []
      if (val?.length === 0) this.selected = []
      else {
        if (['all', 'filtered'].includes(val)) {
          this.selected = this.mode ? this.suppliersList.map(({id}) => id) : this.suppliersList
          this.selectedList = []
        } else {
          this.selected = this.mode ? this.suppliersList.map(({id}) => id) : this.suppliersList
          this.selectedList = [...this.selected]
        }
      }
    },
    dialogSupplier (val) {
      if (val) {
        this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: {
            page_size: 9999
          }
        })
      }
    },
    selected (val) {
     if (!this.$store.state.documents.sendTypeToggleDropdown) this.selectedList = !this.mode ? val.map(({id}) => id) : val
    },
    selectedList (val) {
      if (this.$store.state.documents.sendTypeToggleDropdown === 'perPage') {
        this.auxSelectedList = this.suppliersList.map(({id}) => id)
      } else {
        this.auxSelectedList = val
      }
    }
  },
  created () {
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length) && this.$helpers.hasSomePermission(['view_supplier'])) {
      this.getList()
    }
    if (this.$helpers.hasSomePermission(['view_suppliergroup'])) {
      this.$store.dispatch('suppliers/LIST', {
        resource: 'groups'
      })
      .then((resp) => {
        const index = this.filters.findIndex(({ id }) => id === 'group')
        this.filters[index].choices = resp.data.results.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.non_field_errors)
      })
    }
  },
  methods: {
    clearInput () {
      this.$v.newSupplier.$reset()
      this.$v.contacts.$reset()
      this.newSupplier = {
        name: '',
        tax_id: '',
        tax_settings: {
          cl_activity_description: ''
        },
        country: null,
        state: '',
        address: '',
        group: null
      }
      this.contacts = [{ name: '', email: '', phone: '' }]
    },
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    getFileFormat (val = null) {
      if (!this.types?.find((s) => s === val)) this.types.push(val)
      else {
        this.types.splice(this.types.indexOf(val), 1)
        this.types = [...this.types]
      }
    },
    async createExport () {
      let currentQuery = this.deleteQueries()
      Promise.all(this.types.map(async (item) => {
        try {
          const resp = await this.$store.dispatch('accounts/LIST', {
            resource: 'suppliers/export',
            query: {
              extension: item,
              ...(this.auxSelectedList.length && !Object.keys(currentQuery).length && { id__in: this.auxSelectedList.toString() })
            },
            loader: false,
            responseType: 'arraybuffer'
          })
          const content = Buffer.from(resp.data, 'base64')
          saveAs(new Blob([content], { type: `application/${item.toLowerCase()}` }), `Listado de proveedores.${item.toLowerCase()}`)
        } catch (e) {
          this.$dialog.message.error('Ha ocurrido un error en la descarga')
        }
      }))
      this.types = []
      this.dialogExport = false
    },
    deleteQueries () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      if (currentQuery.name) delete currentQuery.name
      return currentQuery
    },
    handleTableAddToGroup (item = {}, action = 'add') {
      this.current = item
      this.handleGroup(action)
    },
    async handleGroup (action = '') {
      if (action === 'add') {
        await this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: {
            page_size: 9999
          }
        })
        this.dialogAddGroup = true
      } else this.dialogDelete = true
    },
    memberRemove (item) {
      const index = this.newGroup.members.indexOf(item)
      if (index >= 0) this.newGroup.members.splice(index, 1)
    },
    groupsRemove (item) {
      const index = this.groups.indexOf(item)
      if (index >= 0) this.groups.splice(index, 1)
    },
    async addToGroup () {
      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.current.id,
        query: {
          ...currentQuery
        },
        payload: {
          group: this.group
        }
      })
      .then(() => {
        this.$dialog.message.info('Los proveedores han sido asignados correctamente al grupo.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.groups = []
        this.dialogAddGroup = false
        this.createGroupLoading = false
      })
    },
    createGroup () {
      this.$v.newGroup.$touch()
      if (this.$v.newGroup.$invalid) {
        return false
      }

      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      let payload = this.newGroup
      payload.suppliers = this.selectedList
      if ([1, 3].includes(this.newGroup.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers/groups',
        query: currentQuery,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El grupo ha sido creado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.newGroup.$reset()
        this.dialogGroup = false
        this.createGroupLoading = false
        this.newGroup = {
          name: '',
          visibility: 3,
          description: '',
          suppliers: [],
          members: []
        }
      })
    },
    destroySuppliertGroups () {
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.current.id,
        payload: {
          group: null
        }
      })
      .then(() => {
        this.$dialog.message.info('El proveedor se ha sido eliminado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.idDelete = ''
        this.confirm = ''
        this.dialogDelete = false
      })
    },
    toggleAll () {
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', '')
      this.selected = !this.isSelected ? [] : this.suppliersList
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handleConfirm () {
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.dialogConfirm = true
      } else {
        this.handleList()
      }
    },
    handleList () {
      const index = this.suppliersListPE.findIndex(({id}) => id === this.currentList[1].id)
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.suppliersListPE[index].list = this.currentList[1].list === 'white' ? 'black' : 'white'
      } else {
        this.suppliersListPE[index].list = null
      }
      this.dialogConfirm = false
    },
    async getList () {
      this.loading = true
      await this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers',
        query: this.$route.query
      })
      this.loading = false
    },
    postSupplier () {
      this.$v.newSupplier.$touch()
      if (this.$v.newSupplier.$invalid) {
        return false
      }
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers',
        payload: { ...this.newSupplier, contacts: this.contacts }
      })
      .then((response) => {
        this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: response.data.id } })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogSupplier = false
      })
    }
  },
  validations: {
    newGroup: {
      name: { required, maxLength: maxLength(64) },
      description: { required, maxLength: maxLength(64) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.newGroup.visibility)
      })
      }
    },
    newSupplier: {
      name: { required, maxLength: maxLength(100) },
      country: { required },
      state: { required },
      address: { },
      tax_id: {
        required,
        format: function (val) {
          return rutValidator(val)
        },
        maxLength: maxLength(16)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.newSupplier.country === 'CL'
          }),
          maxLength: maxLength(64)
        }
      }
    },
    contacts: {
      $each: {
        email: {
         required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>